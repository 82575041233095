<template>
  <v-data-table
    v-model="selectedRows"
    :items="items"
    :headers="activeHeaders"
    :hide-default-footer="hideFooter"
    :footer-props="{
      disablePagination: disablePagination
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-for="column in customColumns" #[column]="{ item }">
      <slot
        :name="`custom-render-${column}`"
        :item="item"
      />
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope" />
    </template>
    <template #item.actions="{ item }">
      <slot name="actions-menu" :item="item" />
    </template>
  </v-data-table>
</template>

<script>
const actionsColumn = {
  value: 'actions',
  text: '',
};
export default {
  name: 'DataTable',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    customColumns: {
      type: Array,
      default: () => [],
    },
    showActionsColumn: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: undefined,
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeaders: [...this.headers.map((header) => ({ ...header, isActive: true })),
        actionsColumn],
      selectedRows: this.value,
    };
  },
  computed: {
    activeHeaders() {
      return this.tableHeaders.filter((header) => header.isActive || header.value === 'actions');
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.selectedRows = val;
      },
    },
  },
  created() {
    this.tableHeaders = [...this.headers.map((header) => ({ ...header, isActive: true })),
      actionsColumn];
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .v-data-table {
    border: 1px solid $grey-light;
    border-radius: $radius-3;
    padding: 0 $spacing-6;
  }
  ::v-deep .v-data-footer__select {
    display: none;
  }
</style>
